import camelcaseKeys from 'camelcase-keys'

const customerFeedbackFromEdges = edges =>
  edges.map(({ feedback: { customer_name, text } }) => ({
    name: customer_name,
    text,
  }))

const extrasFromEdges = edges =>
  edges.map(({ extra: { description, gallery_images, main_imageSharp, short_description, title } }) => ({
    description,
    galleryImages: gallery_images
      .filter(({ image }) => image !== null)
      .map(({ imageSharp }) => imageSharp && imageSharp.childImageSharp.fluid),
    mainImage: main_imageSharp && main_imageSharp.childImageSharp.fluid,
    shortDescription: short_description,
    title: title,
  }))

const productPreviewsFromEdges = edges =>
  edges.map(
    ({ node: { _meta, for_sale, preview_imageSharp, price, short_description, specs_preview, title } }) => ({
      dateCreated: _meta.firstPublicationDate,
      dateUpdated: _meta.lastPublicationDate,
      description: short_description,
      forSale: for_sale,
      image: preview_imageSharp && preview_imageSharp.childImageSharp.fluid,
      materials: specs_preview.reduce(
        (acc, { spec }, i) => `${acc}${acc && i <= specs_preview.length - 1 ? ', ' : ''}${spec}`,
        ''
      ),
      price,
      slug: _meta.uid,
      title: title,
    })
  )

const toContactData = props => ({
  questions: props.questions,
  questionsTitle: props.questions_title,
  title: props.title,
})

const toExtrasData = props => ({
  description: props.description,
  extras: extrasFromEdges(props.extras),
  title: props.title,
})

const toHomeData = ({
  customer_feedback,
  customer_feedback_title,
  email_address,
  extras,
  extras_title,
  for_sale_description,
  for_sale_products,
  for_sale_shipping,
  for_sale_title,
  maker_imageSharp,
  maker_summary,
  maker_title,
  process_image_1Sharp,
  process_image_2Sharp,
  process_summary,
  process_title,
  wood_image_1Sharp,
  wood_image_2Sharp,
  wood_summary,
  wood_title,
}) => ({
  customerFeedback: {
    feedback: customerFeedbackFromEdges(customer_feedback),
    title: customer_feedback_title,
  },
  extras: {
    extras: extrasFromEdges(extras),
    title: extras_title,
  },
  forSale: {
    email: email_address,
    description: for_sale_description,
    products: productPreviewsFromEdges(for_sale_products).filter(({ forSale }) => forSale),
    shipping: for_sale_shipping,
    title: for_sale_title,
  },
  maker: {
    image: maker_imageSharp && maker_imageSharp.childImageSharp.fluid,
    summary: maker_summary,
    title: maker_title,
  },
  process: {
    image1: process_image_1Sharp && process_image_1Sharp.childImageSharp.fluid,
    image2: process_image_2Sharp && process_image_2Sharp.childImageSharp.fluid,
    summary: process_summary,
    title: process_title,
  },
  wood: {
    image1: wood_image_1Sharp && wood_image_1Sharp.childImageSharp.fluid,
    image2: wood_image_2Sharp && wood_image_2Sharp.childImageSharp.fluid,
    summary: wood_summary,
    title: wood_title,
  },
})

const toProcessData = props => ({
  description: props.description,
  steps: props.steps
    .map(({ caption, imageSharp, step_title }) => ({
      caption,
      image: imageSharp && imageSharp.childImageSharp.fluid,
      stepTitle: step_title,
    }))
    .filter(({ image }) => image),
  title: props.title,
})

const toMakerData = props => ({
  slices: props.body.map(({ primary, type }) => ({
    ...getSliceFromPrimary(primary, type),
    type,
  })),
  title: props.title,
})

const toReviewsData = props => ({
  description: props.description,
  email: props.email_address,
  customerFeedback: customerFeedbackFromEdges(props.customer_feedback),
  submissionDescription: props.submission_description,
  title: props.title,
  pressTitle: props.press_title,
  links: props.links
    .map(({ link_url, link_text, link_title, link_description }) => ({
      description: link_description,
      linkText: link_text,
      title: link_title,
      url: link_url.url,
    }))
    .filter(({ url }) => typeof url !== 'undefined'),
  friendsTitle: props.friends_title,
  friends: props.friends
    .map(({ friend_link, friend_link_text, friend_title, friend_description }) => ({
      description: friend_description,
      linkText: friend_link_text,
      title: friend_title,
      url: friend_link.url,
    }))
    .filter(({ url }) => typeof url !== 'undefined'),
})

const toWoodData = props => ({
  description: props.description,
  woods: props.woods
    .map(({ caption, imageSharp, wood_title }) => ({
      caption,
      image: imageSharp && imageSharp.childImageSharp.fluid,
      woodTitle: wood_title,
    }))
    .filter(({ image }) => image),
  title: props.title,
})

const toUkuleleData = props => ({
  description: props.description,
  forSale: props.for_sale,
  images: [
    ...(props.preview_imageSharp ? [props.preview_imageSharp.childImageSharp.fluid] : []),
    ...props.gallery_images
      .filter(({ imageSharp }) => imageSharp)
      .map(({ imageSharp }) => imageSharp.childImageSharp.fluid),
  ],
  price: props.price,
  specs: props.specs.map(({ spec_name, spec_value }) => ({
    name: spec_name,
    value: spec_value,
  })),
  title: props.title,
})

function getSliceFromPrimary(primary, type) {
  switch (type) {
    case 'text_block':
      return {
        data: {
          textBlock: primary.text_block,
        },
      }
    case 'image_with_caption':
      return {
        data: {
          caption: primary.caption,
          image: primary.imageSharp && primary.imageSharp.childImageSharp.fluid,
        },
      }
    default:
      return { data: primary }
  }
}

export {
  productPreviewsFromEdges,
  toContactData,
  toExtrasData,
  toHomeData,
  toMakerData,
  toProcessData,
  toReviewsData,
  toUkuleleData,
  toWoodData,
}
